.productMediaReviews{
    padding: 50px
}
.productMediaReviews .filters{
    padding-bottom: 50px;
    display:flex;    
}
.productMediaReviews .filters .lang{
    padding-left: 10px;    
}

.productMediaReviews .shortdetails{
    display: flex;
}

.productMediaReviews .shortdetails .field{
    padding: 10px;
    width: 30%;
}

.productMediaReviews .buttons{
    display: flex;
    justify-content: flex-end;    
    padding-top:10px;   
    width: 160px; 
}
.productMediaReviews .buttons button{
    margin-left:10px
}
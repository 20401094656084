.attributesValues .listcontainer{
    display: flex;
}
.attributesValues .valuesContainer{
    margin-left: 30px
}
.attributesValues ul{
    list-style: none;
    background-color: white;
    width: fit-content;
    padding: 10px;
    border: 1px solid #d4cdcd;
    cursor: pointer;
    min-width: 200px;
    max-height: 500px;
    overflow: scroll;
}
.attributesValues li:hover{
    background-color: grey;
    color: white;
}
.attributesValues li{    
    padding: 3px;
}
.attributesValues li.selected{
    background-color:#91ccee
}
.attributesDetail .layoutDetail{
    display: flex
}
.attributesDetail .borderList{
    border: 1px solid;
    padding: 10px;
    height: 200px;
    overflow-y: scroll;
}
.attributesDetail .msgGuardaAtt{
    color:red;
    font-size: 12px;
    padding-left: 5px;
}
.attributesDetail .columnRight .title{
    padding-top: 20px;
}
.attributesDetail .layoutDetail .columnleft, .attributesDetail .layoutDetail .columnRight{
    width:50%
}
.valuesDetail .form, .attributesDetail .form{
    display: flex;
    align-items: center;
}
.attributesDetail .form .title{
    padding: 20px;
    min-width: 50%;
}
.valuesDetail .form .title{
    padding: 20px;
    min-width: 25%;
}
.valuesDetail .form .text{
    width: 100%;
}
.valuesDetail .form .text{
    width: 100%;
}
.valuesDetail .buttons, .attributesDetail .buttons {
    display: flex;
    justify-content: flex-end;
}
.valuesDetail .buttons .lastButton,  .attributesDetail .buttons .lastButton{
    margin-left: 10px;
}
.valuesDetail .removeImage{
    padding:10px;
    cursor: pointer;
}
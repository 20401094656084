.productCategories{
    background-color: white;
}
.productCategories .ant-select{
    margin: 10px
}
.productCategories .buttons{
    display: flex;
    justify-content: flex-end;
    padding: 10px
}
.productCategories .buttons .lastButton{
    margin-right: 10px;
}
.ant-table .ant-table-column-title{
	font-size: 14px!important;
	font-family:'Segoe UI'
}
.ant-table{
	font-size: 12px!important;
	font-family:'Segoe UI'
}

.transferOrderDetails .table-orders, .transferOrders .table-orders{
	cursor: pointer;
}
.transferOrderDetails .table-orders.table-row-red, .transferOrders .table-orders.table-row-red{
	color: #b90e0e
}
.transferOrderDetails .table-orders.table-row-red a{
	color: #b90e0e
}

.transferOrderDetails .table-orders.table-row-green, .transferOrders .table-orders.table-row-green{
	color: #35d126
}
.transferOrderDetails .table-orders.table-row-green a{
	color: #35d126
}
.transferOrderDetails .table-orders.table-row-green a:hover{
	text-decoration: underline;
}
.transferOrders .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
	background-color: rgb(174, 174, 174) !important;
}
.ordersToSend {
	width:80%;
	text-align: center;
}
.ordersToSend .listToPrint{
	display: flex;
    list-style: none;
    justify-content: space-between;
}
.ordersToSend .listToPrint .title{
	font-weight: bold;
	border-bottom: 1px solid #000;
}
.printWrapper{
	text-align: end;
}
.productTexts{
    padding: 50px
}
.productTexts .filters{
    padding-bottom: 50px;
    display:flex;    
}
.productTexts .filters .lang{
    padding-left: 10px;    
}
.productTexts .shortdetails h5{
    font-size: 20px;
}
.productTexts .shortdetails .field{
    padding: 10px
}

.productTexts .buttons{
    display: flex;
    justify-content: flex-end;    
    padding-top:30px;    
}
.productTexts .buttons button{
    margin-left:10px
}
.imagethumb-wraper{
    display: flex;
    justify-content:flex-end;
}
.categoriesDetails .buttons{
    border-top: 1px dotted #000;
    padding-top: 5px;
}

.categoriesDetails .title{
    border-bottom: solid 1px #000;
    padding: 2px;
}
.categoriesDetails .content{    
    padding-top: 10px;
}
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// If you want to add custom CSS you can put it here.
@import "custom";

@import 'attributeList';

@import 'categories';

@import 'menu';

@import 'productAttributes';

@import 'productCategories';

@import 'productTexts';

@import 'productMediaReviews';

@import 'productAuthors';

@import 'transferOrders';

body{
    background-color:#ebedef!important;
}

#root{
  font-family: Verdana, Geneva, Tahoma, sans-serif!important;
  font-size: 10px!important;
}
.ant-btn{
  font-size:10px!important
}
.wrapper{
    padding:50px
}
.box img {
    width: 100%;
    height: 100%;
}

.editorformerr {
  border: 1px solid #d00a0a !important;
  
}

.box1 img {
    object-fit: cover;
}

.thumb .box1
{
  width: 60px;
  height: 60px;
}
.thumb .box1 img
{
  width: 100%;
  height: 100%;
}
.ant-table-cell.link
{
  width: 150px;
}
.ant-table-thead > tr > th
{
  text-align: center;
  font-weight: 600;
}
.ant-table-tbody > tr > td
{
  text-align: center;
}

.wrapper-content
{
  padding: 15px;
  /*width: 750px;*/
  margin: 0 auto;
}
.ant-form-item-label > label{
  font-weight: 600;
  width: 130px;
}
.shipping-company-drawer .ant-form-item-label > label{
  width: 180px;
}
.details > .ant-col > label
{
  font-weight: 600;
  font-size: 17px;
}
.ant-row.details {
    border-bottom: 1px #eee solid;
}
.anticon.anticon-arrow-left, .anticon.anticon-plus
{
  vertical-align: 0em;
}
.ant-col.ant-col-16 thumb
{
  margin-bottom: 8px;
}
.left-panel, .right-panel
{
  padding: 15px;
}
.left-panel
{
  min-height: 600px;
  margin-right: 10px;
}
.right-panel
{
  min-height: 600px;
}
.panel-data input, .panel-data .ant-picker
{
  width: 100%;
}
.panel-data input
{
  border: 1px solid #d9d9d9;
}
.panel-data .ant-picker input
{
  border: 0;
}
.panel-data
{
   margin-bottom: 15px;
 }
.shortdetails
{
  border-bottom: 1px #ebedef solid;
  padding-bottom: 15px;
}
.extendeddetails
{
  margin-top:15px;
}
.panel-data fieldset
{
  display: flex;
}
.panel-data fieldset input[type="radio"]
{
  width: auto;
}
.ml-10
{
  margin-left: 10px;
}
.float-right{
  float: right;
}
.error-msg{
  font-size: 12px;
  color: red;
}
.imagename
{
  width: 70%;
  float: left;
}
.imagethumb
{
  height: 100px;
  margin-top: 15px;
}
.ant-cascader-menu{
  height: 50vh!important;
}
.event-wrapper-content
{
  padding: 15px;
}
.alert-bar-div{padding: 20px;
background-color: #e2e2e2; position: relative;}
.exclamation {
  font-size: 22px;
  margin-right: 15px;
  color: #ff4d4f;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 6px;
}
.alert-bar-div .alert-msg {
   position: absolute;
   animation: move 5s linear forwards;
   font-size: 14px;
    color: #ff4d4f;
    bottom: 0;
}
.preview-checkbox-div input{margin-right: 10px;}
.preview-menu-div{background-color: #fff; padding: 20px; border-bottom: 1px solid #f0f0f0; width: 100%; display: flex; position: fixed; bottom: 0px;
  animation-duration:0.8s;
  animation-fill-mode:forwards;
  animation-timing-function:ease-out;}
.preview-menu-div .checkbox-count{font-size: 12px; font-weight: bold;}
.preview-menu-div .preview-menu {
  font-size: 10px;
  display: inline-grid;
  margin-left: 50px;
}
.preview-menu-div .preview-menu a, .previewMenuModal .previewOptions a {
  color: #000;
  text-decoration: none;
}
.preview-menu .anticon-file-image {
  font-size: 16px;
}
.previewMenuModal .ant-modal-footer{display: none;}
.previewMenuModal .previewOptions {
  display: flex;
  justify-content: space-evenly;
}
.mr-1{margin-right: 10px;}
.mr-2{margin-right: 20px;}
.mr-3{margin-right: 30px;}
.previewMenuModal .ant-btn-primary{margin: 0 auto; display: flex;}
.previewMenuModal .sel-option{
  text-align: center;
  margin-bottom: 20px;
}
.disabled {
  background-color: gray;
  text-decoration: none;
  border: 0;
  pointer-events: none;
}
.p-relative{position: relative;}
.footer{margin-top: 75px;}
.sel-option input, .sel-option .ant-select{width: 50% !important;}
.tokenName{position: relative;}
.crossImg {
  position: absolute;
  top: -5px;
  left: 55px;
}
.footer{
    margin-top: 50px;
}
.newRow{float: right;
margin: 20px 0;}
.custom-select{width: 70%;}
.ant-modal.countriesshopModal{width: 800px !important;}
.ant-modal.couponModal{width: 1400px !important;}
.countriesshopModal .ant-modal-footer{display: none;}
.webTextUpdate .ant-drawer-content-wrapper{width: 100% !important;}
.contentDrawer .ant-drawer-content-wrapper{width: 100% !important;}

.shippingcompanytext .ck-restricted-editing_mode_standard{height: 100px !important;}
.webtextchoice{display: flex;}
.webtextchoice .webtext{margin-right: 20px;}
.mr-1{margin-right: 10px;}
.textWebDescription{height: 40px;overflow: hidden;}
.react-colorful{position: absolute!important; z-index: 1;}
.ck-block-toolbar-button{z-index: 1000!important;}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    border:1px solid #444343;
    max-height: 70vh;
}
.addqa .ck.ck-editor {
  width: 500px;
}
.text-right{text-align: right;}
.mr-15{margin-right: 15px;}
.help-page-drawer .ant-drawer-content-wrapper{width: 1300px !important;}
.addQAModal .ant-modal-footer{display: none;}
.rc-virtual-list{
    background-color:#efeffa
}
.previewButtonsWrapper{
    display: flex;
    justify-content: space-between;
}
.bg-white{background-color: white;}
@keyframes move
{
    0%   {
        left: 0%;
      transform: rotate(0deg) ;
    }
    50%  {
      left: 50%;
      transform: rotate(0deg) ;
    }
    100% {
      left: 0%;
      transform: rotate(0deg);}
}
@keyframes slideup {
  0%{bottom:-50px}
  100%{bottom:50px;}
}

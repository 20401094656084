.productAuthors{
    padding-top: 50px
}
.productAuthors ul{
    width: 500px;
}
.productAuthors .list .header{
    padding: 10px 0 10px 0;
    font-weight: bold;
}
.productAuthors .list li{
    display: flex;
    justify-content: space-between;
}
.productAuthors .list li .checkColumn{
    width:120px
}
.productAuthors{
    font-size: 16px;
}